import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import fetch from 'utils/fetch';
import { useBranchId } from 'utils/customHooks';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import EmployeeList from './EmployeeList';
import CreateEmpDialogContent from './CreateEmpDialogContent';
import QRCode from 'react-qr-code';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function EmployeeManagement() {
  const branchId = useBranchId();
  const [addEmpDialogVisibility, setEmpDialogVisibility] = useState(false);
  const [qrDialogVisibility, setQrDialogVisibility] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [otpForWorker, setOtpForWorker] = useState('');
  const [currentWorker, setCurrentWorker] = useState({});

  const { refetch: refetchWorkerList, isFetching: isFetchingList } = useQuery(
    branchId && ['workerList', branchId],
    () =>
      fetch({
        url: 'wms/workforce/worker',
        headers: {
          'x-wh-id': branchId,
        },
        method: 'GET',
      }),
    {
      onSuccess: (data) => {
        //console.log('workerListResponse: ', data);
        setEmployeeList(data);
      },
    }
  );

  const [createWorker] = useMutation(
    ({ empData }) =>
      fetch({
        url: 'wms/workforce/worker',
        method: 'POST',
        headers: {
          'x-wh-id': branchId,
        },
        data: [empData],
      }),
    {
      onSuccess: () => {
        refetchWorkerList();
      },
    }
  );

  const [editWorker] = useMutation(
    ({ empData }) =>
      fetch({
        url: 'wms/workforce/worker',
        method: 'PUT',
        headers: {
          'x-wh-id': branchId,
        },
        data: [empData],
      }),
    {
      onSuccess: () => {
        refetchWorkerList();
      },
    }
  );

  const [getOtpForWorker] = useMutation(
    ({ workerId }) =>
      fetch({
        url: 'wms/workforce/generate_login_otp',
        method: 'POST',
        headers: {
          'x-wh-id': branchId,
        },
        params: {
          workerId: workerId,
        },
      }),
    {
      onSuccess: (data) => {
        setOtpForWorker(data.otp);
        setQrDialogVisibility(true);
      },
    }
  );

  const [logoutWorker] = useMutation(
    ({ workerId }) =>
      fetch({
        url: `wms/workforce/worker/${workerId}/logout`,
        method: 'POST',
        headers: {
          'x-wh-id': branchId,
        },
      }),
    {
      onSuccess: () => {
        refetchWorkerList();
      },
    }
  );

  const [deleteWorker] = useMutation(
    ({ id }) =>
      fetch({
        url: `wms/workforce/worker/${id}`,
        method: 'DELETE',
        headers: {
          'x-wh-id': branchId,
        },
      }),
    {
      onSuccess: () => {
        refetchWorkerList();
      },
    }
  );

  return (
    <div>
      {isFetchingList ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="h-full p-5 md:p-5">
        {/* Employee list section */}
        <EmployeeList
          empList={employeeList}
          onLoginClicked={(emp) => {
            getOtpForWorker({ workerId: emp.id });
          }}
          onEditClicked={(emp) => {
            setCurrentWorker(emp);
            setEmpDialogVisibility(true);
          }}
          onDeleteClicked={(emp) => {
            deleteWorker({ id: emp.id });
          }}
          onLogoutClicked={(emp) => {
            logoutWorker({ workerId: emp.id });
          }}
        />

        {/* Add Employee fab button */}
        <div className="fixed right-5 md:right-5 bottom-5 md:bottom-5">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setCurrentWorker(null);
              setEmpDialogVisibility(true);
            }}
          >
            <AddIcon />
          </Fab>
        </div>

        {/* Add Employee form dialog */}
        <Dialog
          classes={{ paper: 'h-full' }}
          fullWidth={true}
          maxWidth={'sm'}
          onClose={() => setEmpDialogVisibility(false)}
          aria-labelledby="customized-dialog-title"
          open={addEmpDialogVisibility}
        >
          <MuiDialogContent>
            <CreateEmpDialogContent
              selectedEmployee={currentWorker}
              onSubmit={(empData) => {
                const arr = [];
                arr.push(empData);
                if (empData?.id) {
                  editWorker({ empData });
                } else {
                  createWorker({ empData });
                }

                setEmpDialogVisibility(false);
              }}
              onCancel={() => setEmpDialogVisibility(false)}
            />
          </MuiDialogContent>
        </Dialog>

        {/* QR Code dialog for login */}
        <Dialog
          classes={{ paper: 'h-full' }}
          fullWidth={true}
          maxWidth={'sm'}
          onClose={() => setQrDialogVisibility(false)}
          aria-labelledby="customized-dialog-title"
          open={qrDialogVisibility}
        >
          <MuiDialogContent>
            <div className="w-auto h-full flex flex-col items-center">
              <p className="text-2xl font-bold">Scan the QR Code to login</p>
              <div className=" w-auto h-full flex flex-col justify-center items-center">
                {otpForWorker && otpForWorker !== '' ? (
                  <QRCode value={otpForWorker} size={300} />
                ) : null}
                <p className="mt-5 text-lg font-normal">{otpForWorker}</p>
              </div>
              <div className="absolute top-0 right-0">
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    setQrDialogVisibility(false);
                    refetchWorkerList();
                  }}
                >
                  <CancelIcon color="secondary" fontSize="large" />
                </IconButton>
              </div>
            </div>
          </MuiDialogContent>
        </Dialog>
      </div>
    </div>
  );
}
