import React from 'react';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';

export default function CreateEmpDialogContent(props) {
  const { selectedEmployee, onSubmit, onCancel } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitPressed = (data) => {
    // console.log('Data : ', data);
    onSubmit({
      id: selectedEmployee ? selectedEmployee?.id : null,
      code: data?.code,
      name: data?.name,
      phone: data?.phone,
      supervisor: data?.supervisor,
      workerRoles: [],
      storageIdentifiers: {},
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPressed)}>
      {/* Upload button section */}
      {/* <div className="flex flex-col justify-center items-center">
        <input
          accept="image/*"
          className="hidden"
          id="contained-button-file"
          multiple
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
      </div> */}
      {/* Or section */}
      {/* <p className="text-center text-gray-300 text-base md:text-lg antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-bold my-3 md:my-5">
        Or
      </p> */}
      {/* Manual Employee creation section */}
      <div className="flex flex-col">
        <table className="w-full">
          <tr>
            <td className="w-1/3">Employee Code</td>
            <td className="w-2/3">
              <input
                className=" w-full h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
                type="text"
                defaultValue={selectedEmployee ? selectedEmployee?.code : ''}
                {...register('code', { required: 'Code is mandatory' })}
              />
              {errors.code && (
                <p className="text-xs italic text-warn float-right">{errors.code.message}</p>
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/3">Name</td>
            <td className="w-2/3">
              <input
                className=" w-full h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
                type="text"
                defaultValue={selectedEmployee ? selectedEmployee?.name : ''}
                {...register('name', { required: 'Name is mandatory' })}
              />
              {errors.name && (
                <p className="text-xs italic text-warn float-right">{errors.name.message}</p>
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/3">Phone</td>
            <td className="w-2/3">
              <input
                className=" w-full h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
                type="number"
                defaultValue={selectedEmployee ? selectedEmployee?.phone : ''}
                {...register('phone', { required: 'Phone number is mandatory' })}
              />
              {errors.phone && (
                <p className="text-xs italic text-warn float-right">{errors.phone.message}</p>
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/3">Supervisor</td>
            <td className="w-2/3">
              <input
                className="w-full rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
                type="checkbox"
                defaultChecked={selectedEmployee ? selectedEmployee?.supervisor : false}
                {...register('supervisor')}
              />
            </td>
          </tr>
        </table>

        <div className="flex flex-row justify-center items-center space-x-2 my-5">
          {/* <Button
            className="bg-green-500 text-white"
            variant="contained"
            component="span"
            onClick={() => onSubmitPressed()}
          >
            Create
          </Button> */}
          <input
            className="w-24 h-9 rounded-md md:rounded-md shadow md:shadow-lg bg-green-500 text-white"
            type="submit"
            value="Create"
          />
          <Button
            className="bg-red-500 text-white"
            variant="contained"
            component="span"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
}
