import React, { useState } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function EmployeeList(props) {
  const { empList, onLoginClicked, onEditClicked, onDeleteClicked, onLogoutClicked } = props;

  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
      {empList?.map((emp) => {
        return (
          <EmployeeItem
            emp={emp}
            key={emp.id}
            onMenuOptionSelected={(type) => {
              if (type === 'login') {
                onLoginClicked(emp);
              } else if (type === 'edit') {
                onEditClicked(emp);
              } else if (type === 'delete') {
                onDeleteClicked(emp);
              } else if (type === 'logout') {
                onLogoutClicked(emp);
              }
            }}
          />
        );
      })}
    </div>
  );
}

export function EmployeeItem(props) {
  const { emp, onMenuOptionSelected } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose(type) {
    setAnchorEl(null);
    onMenuOptionSelected(type);
  }

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white" key={emp?.id}>
      <div className="flex items-center justify-between">
        <div className="flex min-w-0 items-center justify-start space-x-2 pl-2">
          <AccountCircleIcon fontSize="large" className="min-w-max" />
          <p className="text-base truncate whitespace-nowrap md:text-lg antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold my-1">
            {emp?.name}
          </p>
        </div>
        <div className="flex justify-end">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose('')}
          >
            <MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
            <MenuItem onClick={() => handleClose('delete')}>Delete</MenuItem>
          </Menu>
        </div>
      </div>
      <p className="text-base md:text-md antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal my-1 pl-12">
        {emp?.code}
      </p>
      {emp?.online ? (
        <p className="text-sm md:text-sm antialiased sm:subpixel-antialiased md:antialiased font-light md:font-light my-1 text-green pl-12">
          Online
        </p>
      ) : (
        <p className="text-sm md:text-sm antialiased sm:subpixel-antialiased md:antialiased font-light md:font-light my-1 text-inactive pl-12">
          Offline
        </p>
      )}
      <div className="flex justify-center mb-2">
        <div
          onClick={() => (emp.online ? handleClose('logout') : handleClose('login'))}
          className="border border-base border-opacity-50 w-10/12 py-1 rounded-update text-center cursor-pointer"
        >
          <p className="text-base font-normal">{emp.online ? 'Logout' : 'Login'}</p>
        </div>
      </div>
    </div>
  );
}
